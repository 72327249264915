//https://w3bits.com/sass-mixins/

@mixin box($width, $height: $width) {
    width: $width;
    height: $height;
}
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}





//POsition
@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}

// Positioning helpers
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}

/*
.menu li {
    @include relative;
}

.sub-menu {
    @include absolute(top 100% left 0);
}

.sticky-bar {
    @include fixed(top 0 left 0);
}*/







@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }
    @if $default {
        #{$property}: #{$value};
    }
}


/*
html {
  @include prefix('box-sizing', 'border-box', moz webkit);
}

*,
*:before,
*:after {
  @include prefix('box-sizing', 'inherit', moz webkit);
}
*/


@mixin screen($size) {
    $desktop: "(min-width: 1024px)";
    $tablet: "(min-width: 768px) and (max-width: 1023px)";
    $mobile: "(max-width: 767px)";
    @if $size == desktop {
        @media only screen and #{$desktop} {
            @content;
        }
    }
    @else if $size == tablet {
        @media only screen and #{$tablet} {
            @content;
        }
    }
    @else if $size == mobile {
        @media only screen and #{$mobile} {
            @content;
        }
    }
    @else {
        @media only screen and #{$size} {
            @content;
        }
    }
}

/*

.wrapper {
    margin: 0 auto;
    width: 100%;
    @include screen('tablet') {
        width: 90%;
    }

    @include screen('desktop') {
        width: 85%;
    }
}

.wrapper {
  @include screen('(min-width: 1367px)') {
    width: 1280px;
  }
}
*/





@mixin retina($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        /* Serving 2x image on Retina display */
        background-image: url($image);
        background-size: $width $height;
    }
}

/*
.logo {
    background-image: url("img/logo.png");
    @include retina("img/logo@2x.png", 100px, 21px);
}*/





@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}
/*
.gradient {
    @include gradient(#07c, #06f, vertical);
}*/




@mixin center-vertically {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}

/*
.vc-box {
    @include center-vertically;
}*/




@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf')  format('truetype'),
        url($file-name + '.svg##{$font-name}')  format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

/*
@include font-face("MyFont", "path/to/MyFont", $style: normal, $weight: normal);
*/












@mixin keyframes($animation-name) {
    @-webkit-keyframes $animation-name {
        @content;
    }
    @-moz-keyframes $animation-name {
        @content;
    }
    @-ms-keyframes $animation-name {
        @content;
    }
    @-o-keyframes $animation-name {
        @content;
    }
    @keyframes $animation-name {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

/*

Usage:

@include keyframes(slide-down) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}

.element {
  width: 100px;
  height: 100px;
  background: black;
  @include animation('slide-down 5s 3');
}
*/






$image-path: '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';

@mixin background-image($name, $size:false) {
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size) {
        background-size: $size;
    }
    .no-svg & {
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}








@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}








// Browser Prefixes
@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    transform-origin: $origin;
}
