@import "flickity/css/flickity.css";
@import "mixins";
$verdeAfilog:#00b497;

html , body
{
  font-family: Arial, sans-serif !important;
  font-size: 18px;
  background-color:#fdf9f6;
}

body.body-photo
{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.botStart
{
  cursor: pointer;
  &:hover
  {
    color: #111;
  }
}
.pointer
{
  cursor: pointer;
}

.carousel-main
{
  background-color: white;
}
.img-afilog
{
  position: absolute;

  height: calc(100vh - 160px);
  width: calc(100vw - 160px);
  margin-top: 80px;
  margin-left: 80px;

  background-color: #fafafa;
  @include screen(mobile)
  {
    margin-left: 0;
    top:60px !important;
    left:0 !important;
    height: calc(100vh);
    width: calc(100vw);
  }
}
.contenedor-afilog
{
  width: calc(100vw - 100px);
  @include screen(mobile)
  {
    width: calc(100vw - 40px);
  }
}

.thumb-afilog
{
  width: 100px;
  height: 50px;
  background-size: cover;
  background-position: center center;
  background-repeat:no-repeat ;

  border-top: 3px solid transparent ;
}


.selector .thumb-afilog.is-nav-selected {
  border-top: 3px solid $verdeAfilog ;
}
.selector
{
  @include screen(mobile)
  {
    display: none;
  }
}

.name-afilog
{
  position: absolute;
  z-index: 100;
  left: 0px;
  bottom: 0px;
  width: 100vw;
  transition:  bottom 1.2s cubic-bezier(.19, 1, .22, 1);
  height: 25px;


  .tit-foto
  {
    color: black;
    padding:5px 10px;
    position: absolute;
    font-weight: 200;
    text-align: center;
  }
  &.openednav
  {
    bottom: 38px;
    @include screen(mobile)
    {
      bottom: 0;
    }
  }

}




.bg-afilog
{background-color: $verdeAfilog}

header
{
  z-index: 10000;
  background-color: transparent;
  margin-top: 10px;
}

.init
{
  position: absolute;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;

  pointer-events: none;
}

.show.init
{pointer-events: all;}


.selector
{
  position: absolute;
  width: 100%;
  bottom:0;
}


/* smaller, dark, rounded square */
.flickity-button {
  background: #333;
}
.flickity-button:hover {
  background: $verdeAfilog;
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
/* icon color */
.flickity-button-icon {
  fill: white;
}
/* position outside */
.flickity-prev-next-button.previous {
  left: 40px;
}
.flickity-prev-next-button.next {
  right: 40px;
}

.flickity-page-dots {
  bottom: 22px;
  @include screen(mobile)
  {
    display: none;
  }
}
/* dots are lines */
.flickity-page-dots .dot {
  height: 4px;
  width: 40px;
  margin: 0;
  border-radius: 0;
}
.flickity-page-dots .dot.is-selected {
  background: $verdeAfilog;
}

.video-container {
  overflow: hidden;
  position: relative;
  margin:auto;
  max-width: 1500px;
}
@media (max-width: 1366px)
{
  .video-container {
    max-width: 1200px;
  }
}
.uk-countdown-number
{
  font-size: 50px !important;
}
